// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound-container {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.notFound-img {
  width: 100%;
  height: auto;
}
.notFound-text {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .notFound-text {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/notFound/style-notFound.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".notFound-container {\n  margin-left: auto;\n  margin-right: auto;\n  width: fit-content;\n}\n.notFound-img {\n  width: 100%;\n  height: auto;\n}\n.notFound-text {\n  display: flex;\n  justify-content: center;\n}\n@media (max-width: 768px) {\n  .notFound-text {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
