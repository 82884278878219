// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #ffe1f2;
  padding-top: 50px;
  padding-bottom: 60px;
}
.footer a {
  text-decoration: none;
  color: #580031;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 95%;
  margin: auto;
  max-width: 1440px;
}
@media (max-width: 767px) {
  .footer-container {
    display: block;
  }
}
.footer-block-item,
.adress {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.adress img {
  width: fit-content;
}
.footer-block-item img {
  width: fit-content;
}
.footer-logos {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.phone-number {
  font-size: 32px;
}
.dev-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dev-links a {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/style-footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE;IACE,cAAc;EAChB;AACF;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".footer {\n  background-color: #ffe1f2;\n  padding-top: 50px;\n  padding-bottom: 60px;\n}\n.footer a {\n  text-decoration: none;\n  color: #580031;\n}\n.footer-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n  width: 95%;\n  margin: auto;\n  max-width: 1440px;\n}\n@media (max-width: 767px) {\n  .footer-container {\n    display: block;\n  }\n}\n.footer-block-item,\n.adress {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.adress img {\n  width: fit-content;\n}\n.footer-block-item img {\n  width: fit-content;\n}\n.footer-logos {\n  display: flex;\n  flex-direction: row;\n  gap: 15px;\n}\n.phone-number {\n  font-size: 32px;\n}\n.dev-links {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.dev-links a {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
