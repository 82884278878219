// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .contacts-container {
    display: block;
    width: 100%;
  }
}
.contacts-main-img {
  width: 30%;
  height: auto;
}
@media (max-width: 767px) {
  .contacts-main-img {
    width: 100%;
  }
}
.contacts-main-text {
  font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/contacts/style-contacts.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,mBAAmB;AACrB;AACA;EACE;IACE,cAAc;IACd,WAAW;EACb;AACF;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE,eAAe;AACjB","sourcesContent":[".contacts-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  margin-bottom: 20px;\n}\n@media (max-width: 767px) {\n  .contacts-container {\n    display: block;\n    width: 100%;\n  }\n}\n.contacts-main-img {\n  width: 30%;\n  height: auto;\n}\n@media (max-width: 767px) {\n  .contacts-main-img {\n    width: 100%;\n  }\n}\n.contacts-main-text {\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
