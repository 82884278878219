// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}
main {
  width: 95%;
  margin: auto;
  max-width: 1440px;
}
h1 {
  font-size: 40px;
  line-height: 47px;
  margin-top: 15px;
  margin-bottom: 15px;
}
p,
h1 {
  color: #580031;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,SAAS;AACX;AACA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;;EAEE,cAAc;AAChB","sourcesContent":["body {\n  font-family: \"Roboto\", sans-serif;\n  margin: 0;\n}\nmain {\n  width: 95%;\n  margin: auto;\n  max-width: 1440px;\n}\nh1 {\n  font-size: 40px;\n  line-height: 47px;\n  margin-top: 15px;\n  margin-bottom: 15px;\n}\np,\nh1 {\n  color: #580031;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
